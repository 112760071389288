<template>
  <div class="bg-white py-md-2 py-2 px-md-4 position-relative mb-3">
    <div class="content-wrapper d-md-flex justify-content-between px-3">
      <div>
        <div class="d-flex align-items-center mb-2">
          <router-link class="pointer h5 m-0" :to="`/homologation/list/`">
            <b-icon icon="arrow-left" class="mr-2" />
          </router-link>
          <skeleton v-if="!homologation.name" height="24px" :rows="1" grid="1" gap="10" />
          <section v-else>
            <span class="d-block text-muted">Homologação</span>
            <h5 class="mb-2">{{ homologation.name }}</h5>
            <span class="d-block text-muted">Tomador</span>
            <p class="m-0">{{ homologation.borrower.social_reason }}</p>
          </section>
        </div>

        <div class="mt-2 text-muted d-flex align-items-center">
          <b-icon icon="calendar3" />
          <span class="mx-2">{{ homologation.date_init | dateFull }}</span> à
          <span class="mx-2"> {{ homologation.date_end | dateFull }}</span>
        </div>
        <div class="mt-2 text-muted d-flex align-items-center gap-2">
          <span>Progresso</span>
          <b-progress max="100" height="12px" class="w-100">
            <b-progress-bar :value="homologation.percentage" />
          </b-progress>
          <span class="d-flex align-items-center">
            {{ homologation.percentage }}%
          </span>
        </div>
      </div>

      <b-icon v-if="is_mobile" style="right: 23px; top: 12px" class="position-absolute h4"
        v-b-toggle.sidebar-details-homologation icon="info-circle" />
      <div v-else>
        <div v-permission="'service.view_service_notifications'"></div>

        <b-button variant="link" class="mt-3" v-b-toggle.sidebar-details-homologation>
          Mais detalhes da Homologação
        </b-button>
      </div>
    </div>
    <b-sidebar size="md" right align="left" shadow backdrop :width="is_mobile ? '86%' : '40%'"
      id="sidebar-details-homologation">
      <homologation-detail :homologation="homologation" />
    </b-sidebar>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import HomologationDetail from "./HomologationDetail.vue";

export default {
  components: {
    HomologationDetail,
  },

  props: {
    back: {
      type: String,
    },
  },
  data() {
    return {
      homologation_id: "",
      homologation: {
        notifications: [],
      },
    };
  },
  computed: {
    ...mapGetters(["is_provider", "is_mobile"]),
  },
  methods: {
    ...mapActions(["hide_application_header", "get_homologation"]),
    sliceAvatar() {
      const length = this.homologation?.notifications?.length;
      if (length > 4) {
        return length - 4;
      }
      return 0;
    },
  },
  beforeDestroy() {
    this.hide_application_header(true);
  },
  mounted() {
    this.hide_application_header(false);
    this.homologation_id = this.$route.params.homologation_id;
    this.get_homologation({ id: this.homologation_id }).then(({ data }) => {
      this.homologation = data;
    });
  },
};
</script>
